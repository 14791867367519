import { sidebarIcons } from "./sidebarIcons";

const {
  DashBoardIcon,
  NotificationIcon,
  SellerIcon,
  ProductIcon,
  seoIcon,
  CustomerIcon,
  MarketingIcon,
  EmployeIcon,
  SettingIcon,
  CouponIcon,
  GiftCardIcon,
  OrdersIcon,
  OtherIcon,
  ReportIcon,
  ThemeIcon,
  FlagsIcon,
  cacheIcon,
} = sidebarIcons;
export const sidebarLinks = [
  {
    name: "Dashboard",
    link: "/dashboard",
    icon: DashBoardIcon,
    permission: "dashboard",
  },
  {
    name: "Orders",
    link: "/orders/list",
    icon: OrdersIcon,
    sublinks: [
      {
        name: "Order Management",
        link: "/orders/list",
        permission: "order-management",
      },
      {
        name: "Refund Approvals",
        link: "/orders/refund-approvals",
        permission: "order-refund-list",
      },
    ],
  },
  {
    name: "Notifications",
    link: "/notifications/orders",
    icon: NotificationIcon,
    sublinks: [
      {
        name: "Orders",
        link: "/notifications/orders",
        permission: "notifications",
      },
      {
        name: "Seller",
        link: "/notifications/sellers",
        permission: "notifications",
      },
    ],
  },
  {
    name: "Disputes",
    link: "/disputes/braintree",
    icon: NotificationIcon,
    sublinks: [
      {
        name: "Braintree Disputes",
        link: "/disputes/braintree",
        permission: "order-management",
      },
      {
        name: "Disputes Management",
        link: "/disputes/managments",
        permission: "order-management",
      },
    ],
  },
  {
    name: "Seller",
    link: "/seller/stores",
    icon: SellerIcon,
    sublinks: [
      {
        name: "Seller Stores",
        link: "/seller/stores",
        permission: "store-list",
      },
      {
        name: "Payout Methods Approval",
        link: "/seller/payout-approval",
        permission: "hyperwallet-seller-listing",
      },
      {
        name: "Seller Payout Methods",
        link: "/seller/payout-methods",
        permission: "payout-list",
      },
      {
        name: "Announcements",
        link: "/seller/announcements",
        permission: "announcements",
      },
      {
        name: "Order Payments Approval",
        link: "/seller/order-payout-approval",
        permission: "seller-payment-approval",
      },
      {
        name: "Seller Groups",
        link: "/seller/groups",
        permission: "seller-group-list",
      },
      {
        name: "Seller Restrictions",
        link: "/seller/restriction",
        permission: "restricted-sellers",
      },
      {
        name: "Withdraw Requests",
        link: "/seller/withdraw-requests",
        permission: "withdraw",
      },
      {
        name: "KYC Records",
        link: "/seller/kyc-management",
        permission: "kyc",
      },
    ],
  },
  {
    name: "Products",
    link: "/products/list",
    icon: ProductIcon,
    sublinks: [
      {
        name: "All Products",
        link: "/products/list",
        permission: "product-list",
      },
      {
        name: "Products Approval",
        link: "/products/approval",
        permission: "seller_products",
      },
      {
        name: "Product Groups",
        link: "/products/groups",
        permission: "product-group-list",
      },
      {
        name: "Product Categories",
        link: "/products/categories",
        permission: "categories",
      },
      {
        name: "Product Collections",
        link: "/products/collections",
        permission: "collection-list",
      },
      {
        name: "Trending Products",
        link: "/products/trending",
        permission: "trending-product",
      },
    ],
  },
  {
    name: "SEO",
    link: "/seo-keywords/related-groups",
    icon: seoIcon,
    sublinks: [
      // {
      //   name: "SEO Keywords",
      //   link: "/seo-keywords/list",
      //   permission: "seo",
      // },
      // {
      //   name: "SEO Collections",
      //   link: "/seo-keywords/collections",
      //   permission: "seo",
      // },
      {
        name: "Related Groups",
        link: "/seo-keywords/related-groups",
        permission: "seo",
      },
    ],
  },
  {
    name: "Customers",
    link: "/customers/list",
    icon: CustomerIcon,
    sublinks: [
      {
        name: "All Customers",
        link: "/customers/list",
        permission: "customers",
      },
    ],
  },
  {
    name: "Employees",
    link: "/employees/list",
    icon: EmployeIcon,
    sublinks: [
      {
        name: "All Employees",
        link: "/employees/list",
        permission: "user-list",
      },
      {
        name: "Tasks Dashboard",
        link: "/employees/tasks",
        permission: "tasks-dashboard",
      },

      {
        name: "My Tasks",
        link: "/employees/my-task",
        permission: "my-task",
      },
    ],
  },
  {
    name: "Marketing",
    link: "/marketing/promotions",
    icon: MarketingIcon,
    sublinks: [
      {
        name: "Promotions",
        link: "/marketing/promotions",
        permission: "promotion-list",
      },
      {
        name: "Marketplace Feeds",
        link: "/marketing/marketplace-feed",
        permission: "marketplace-feed",
      },
      {
        name: "Products Ad",
        link: "/marketing/product-ads",
        permission: "product-ad",
      },
      {
        name: "Ads",
        link: "/marketing/ads/placements",
        subLinks: [
          {
            name: "Placements",
            link: "/marketing/ads/placements",
            permission: "ads-placements",
          },
          {
            name: "Platforms",
            link: "/marketing/ads/platform",
            permission: "ads-platforms",
          },
          {
            name: "Centers",
            link: "/marketing/ads/centers",
            permission: "ads-centers",
          },
        ],
      },
      {
        name: "Campaign",
        link: "/marketing/campaigns",
        permission: "campaign-list",
      },
    ],
  },
  {
    name: "Settings",
    link: "/settings/roles",
    icon: SettingIcon,
    sublinks: [
      {
        name: "Roles",
        link: "/settings/roles",
        permission: "role-list",
      },
    ],
  },
  {
    name: "Gift Cards",
    link: "/gift-cards/list",
    icon: GiftCardIcon,
    sublinks: [
      {
        name: "All Gift Cards",
        link: "/gift-cards/list",
        permission: "gift-list",
      },
      {
        name: "Gift Card Approvals",
        link: "/gift-cards/approvals",
        permission: "gift-approval",
      },
    ],
  },
  {
    name: "Coupons",
    link: "/coupons/list",
    icon: CouponIcon,
    sublinks: [
      {
        name: "All Coupons",
        link: "/coupons/list",
        permission: "coupon-list",
      },
    ],
  },
  {
    name: "Stats",
    link: "/reports/over-time",
    icon: ReportIcon,
    sublinks: [
      {
        name: "Analytics",
        link: "/reports/analytics?type=country",
        permission: "reports",
      },
      {
        name: "Over Time",
        link: "/reports/over-time",
        permission: "reports",
      },
      {
        name: "Sales By Seller",
        link: "/reports/sales-by-seller",
        permission: "reports",
      },
      {
        name: "Sales By Channel",
        link: "/reports/sales-by-channel",
        permission: "reports",
      },
      {
        name: "Product Views and Purchases",
        link: "/reports/product-views-purchases",
        permission: "reports",
      },
      {
        name: "Store States",
        link: "/reports/product-views-store",
        permission: "reports",
      },
    ],
  },
  {
    name: "Others",
    link: "/others/schedule-command",
    icon: OtherIcon,
    sublinks: [
      {
        name: "Schedule Commands",
        link: "/others/schedule-command",
        permission: "schedule-command",
      },
      {
        name: "Response Logs",
        link: "/others/response-logs",
        permission: "response-log",
      },
      {
        name: "Complaints",
        link: "/others/complaints",
        permission: "complaints",
      },
      {
        name: "DMCA",
        link: "/others/dmca-emails",
        permission: "dmca-list",
      },
      {
        name: "Bugs Report",
        link: "/others/bugs-report",
        permission: "bugs",
      },
      {
        name: "Disputed Reviews",
        link: "/others/dispute-review",
        permission: "reviewit-dispute",
      },
    ],
  },
  {
    name: "Theme",
    link: "/theme/widgets",
    icon: ThemeIcon,
    sublinks: [
      {
        name: "Widgets",
        link: "/theme/widgets",
        permission: "widgets",
      },
      {
        name: "Mobile App",
        link: "/theme/mobile-app",
        permission: "app-welcome-list",
      },
    ],
  },
  {
    name: "Flags",
    link: "/flags/keywords",
    icon: FlagsIcon,
    sublinks: [
      {
        name: "Keywords",
        link: "/flags/keywords",
        permission: "keyword-feed",
      },
      {
        name: "Adult Keywords",
        link: "/flags/adult-keywords",
        permission: "keyword-detect",
      },
    ],
  },
  {
    name: "Cache",
    link: "/cache",
    icon: cacheIcon,
    permission: "caches-list",
  },
];
