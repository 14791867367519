import React, { useCallback, useEffect, useRef, useState } from "react";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimarySearch from "components/Common/PrimarySearch";
import TopHeading from "components/Common/TopHeading";
import { CardBody, Card } from "reactstrap";
import PrimaryTable from "components/Common/PrimaryTable";
import { useSearchParams } from "react-router-dom";
import {
  exportProductViewsAndPurchases,
  getProductPurchases,
} from "services/ReportsServices";
import moment from "moment";
import DateRangePicker from "components/Common/DateRangePicker";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PolygonIcon from "assets/images/polygon.svg";
import { setPageTitle } from "utils/pageTitle";
import ExportButton from "components/Common/ExportButton";
import viewIcon from "assets/images/rectangle-arrow.svg";
import { openLinkInNewTab } from "utils/linkHandling";

const ProductPurchases = () => {
  const [urlParams, setUrlParams] = useSearchParams();
  const [search, setSearch] = useState();
  const [pageNo, setPageNo] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [sortInfo, setSortInfo] = useState({ field: "views", order: "desc" });
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showCalendar, setShowCalendar] = useState(false);
  const dateRef = useRef(null);

  useEffect(() => {
    setPageTitle("Product Views and Purchases - Inspire Uplift");
  }, []);

  useEffect(() => {
    const searchParam = urlParams?.get("search") || undefined;
    setSearch(searchParam);
    const pageParam = urlParams?.get("page") || undefined;
    setPageNo(pageParam || 1);
    let startParam = urlParams?.get("start") || new Date();
    let endParam = urlParams?.get("end") || new Date();
    const eventStartDate = moment(
      startParam || new Date(),
      "YYYY-MM-DD"
    ).toDate();
    const eventEndDate = moment(endParam || new Date(), "YYYY-MM-DD").toDate();
    setDates([
      {
        startDate: eventStartDate,
        endDate: eventEndDate,
        key: "selection",
      },
    ]);


    const sortByParam = urlParams?.get("sort_by");
    const sortOfParam = urlParams?.get("sort_of");

    setSortInfo(prevSortInfo => ({
      ...prevSortInfo,
      order: sortByParam,
      field: sortOfParam,
    }));

    const start = moment(startParam).startOf("day").format("YYYY-MM-DD HH:mm:ss");
    const end = moment(endParam).endOf("day").format("YYYY-MM-DD HH:mm:ss");
    setLoading(true);
    getProductPurchases(
      start,
      end,
      pageParam,
      searchParam,
      sortOfParam,
      sortByParam
    )
      .then(res => {
        setProductData(res.data.data);
        setPageCount(res?.data?.last_page);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, [urlParams]);

  const handleColumnClick = column => {
    handleFilter(
      "sort_of",
      column.dataField,
      "sort_by",
      column.dataField == sortInfo?.field
        ? sortInfo?.order === "asc"
          ? "desc"
          : "asc"
        : "desc"
    );
  };

  const columns = [
    {
      dataField: "title",
      text: "Item Name",
      formatter: (cell, row) => (
        <a
          className="primary-text product-title-container"
          target="__blank"
          href={
            window.location.hostname.includes(".itdeptiu")
              ? `https://staging.itdeptiu.com${row.url}`
              : `https://www.inspireuplift.com${row.url}`
          }
        >
          {row.title}
        </a>
      ),
      headerStyle: { width: "35%" },
    },
    {
      dataField: "views",
      text: "Views",
      headerFormatter: column => (
        <div
          className="cursor-pointer"
          onClick={() => handleColumnClick(column)}
        >
          Views{" "}
          {sortInfo.field === "views" && (
            <img
              src={PolygonIcon}
              alt="polygon icon"
              className={`${sortInfo.order == "asc" && "rotate-180"}`}
            />
          )}
        </div>
      ),
    },
    {
      dataField: "cart",
      text: "Add to Cart",
      formatter: (cell, row) => <div>{row.addToCart}</div>,
      headerFormatter: column => (
        <div
          className="cursor-pointer"
          onClick={() => handleColumnClick(column)}
        >
          Add to Cart{" "}
          {sortInfo.field === "cart" && (
            <img
              src={PolygonIcon}
              alt="polygon icon"
              className={`${sortInfo.order == "asc" && "rotate-180"}`}
            />
          )}
        </div>
      ),
    },
    {
      dataField: "purchase",
      text: "Purchases",
      formatter: (cell, row) => <div>{row?.purchases}</div>,
      headerFormatter: column => (
        <div
          className="cursor-pointer"
          onClick={() => handleColumnClick(column)}
        >
          Purchases{" "}
          {sortInfo.field === "purchase" && (
            <img
              src={PolygonIcon}
              alt="polygon icon"
              className={`${sortInfo.order == "asc" && "rotate-180"}`}
            />
          )}
        </div>
      ),
    },
    {
      dataField: "conversion",
      text: "Conversion",
      formatter: (cell, row) => {
        return <div>{row?.conversion_rate}%</div>;
      },
    },
    {
      dataField: "revenue",
      text: "Revenue",
      formatter: (cell, row) => <div>${row?.total_revenue?.toFixed(2)}</div>,
      headerFormatter: column => (
        <div
          className="cursor-pointer"
          onClick={() => handleColumnClick(column)}
        >
          Revenue{" "}
          {sortInfo.field === "revenue" && (
            <img
              src={PolygonIcon}
              alt="polygon icon"
              className={`${sortInfo.order == "asc" && "rotate-180"}`}
            />
          )}
        </div>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div
          className="action-btn edit-icon-bg cursor-pointer"
          onClick={() => handleView(row.product_id)}
        >
          <img src={viewIcon} height={18} width={18} alt="view icon" />
        </div>
      ),
    },
  ];

  const data = productData?.map(item => {
    return {
      ...item,
    };
  });

  const handleView = useCallback(
    id => {
      const startDateElement = dateRef.current.querySelector(
        ".product-purchase-start-date"
      );
      const endDateElement = dateRef.current.querySelector(
        ".product-purchase-end-date"
      );
      const startDate = startDateElement
        ? startDateElement.textContent.trim()
        : new Date();
      const endDate = endDateElement
        ? endDateElement.textContent.trim()
        : new Date();
      const url = `${
        window?.location?.origin
      }/reports/product-views-purchases/${id}?start=${moment(startDate)?.format(
        "YYYY-MM-DD"
      )}&end=${moment(endDate)?.format("YYYY-MM-DD")}`;
      openLinkInNewTab(url, "_blank");
    },
    [dates]
  );

  const handleFilter = (key, value, key2, value2) => {
    const existingParams = Object.fromEntries(urlParams?.entries());
    if (key && value) {
      existingParams[key] = value;
      if (key2) {
        existingParams[key2] = value2;
      }
    } else {
      delete existingParams[key];
    }
    if (existingParams?.hasOwnProperty("page")) {
      delete existingParams["page"];
    }
    setUrlParams(existingParams);
  };

  const handleDatePicker = item => {
    setDates([
      {
        startDate: moment(item.selection.startDate)
          .local()
          .startOf("day")
          .toDate(),
        endDate: moment(item.selection.endDate).local().endOf("day").toDate(),
        key: "selection",
      },
    ]);
  };

  const handleDownloadFile = async () => {
    const start = moment(dates[0].startDate).startOf("day").format("YYYY-MM-DD HH:mm:ss");
    const end = moment(dates[0].endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss");
    const sort_by = urlParams?.get("sort_by") || undefined;
    const sort_order = urlParams?.get("sort_of") || undefined;
    const response = await exportProductViewsAndPurchases(
      start,
      end,
      sort_by,
      sort_order
    );
    return response;
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="mb-4">
        <TopHeading heading={"Product Views and Purchases"} />
      </div>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <PrimarySearch
                handleKeyPress={e => {
                  if (e.key == "Enter") {
                    handleFilter("search", e.target.value);
                  }
                }}
                handleChange={e => setSearch(e.target.value)}
                value={search}
                placeholder={"Search by item name"}
              />
              <div className="position-relative ms-3">
                <div
                  className="form-control cursor-pointer rounded-full"
                  style={{ color: "#74788D", width: "max-content" }}
                  onClick={() => setShowCalendar(true)}
                  ref={dateRef}
                >
                  <span className="product-purchase-start-date">
                    {moment(dates[0].startDate).format("DD MMM YYYY")}
                  </span>
                  -{" "}
                  <span className="product-purchase-end-date">
                    {moment(dates[0].endDate).format("DD MMM YYYY")}
                  </span>
                </div>

                {showCalendar && (
                  <>
                    <div
                      className="position-fixed top-0 start-0 w-100 h-100"
                      style={{ zIndex: "10" }}
                      onClick={() => setShowCalendar(false)}
                    ></div>
                    <DateRangePicker
                      dates={dates}
                      handleDatePicker={handleDatePicker}
                      handleSubmit={() => {
                        handleFilter(
                          "start",
                          moment(dates[0].startDate).format("YYYY-MM-DD"),
                          "end",
                          moment(dates[0].endDate).format("YYYY-MM-DD")
                        );
                        setShowCalendar(false);
                      }}
                      // minDate={new Date()}
                      maxDate={new Date()}
                    />
                  </>
                )}
              </div>
            </div>
            <div>
              <ExportButton
                isLoading={loading}
                setIsLoading={setLoading}
                buttonText="Export Views"
                fetchData={handleDownloadFile}
              />
            </div>
          </div>

          <div className="table-responsive mt-4">
            <PrimaryTable
              columns={columns}
              data={data}
              bordered={false}
              keyField="order_number"
            />
          </div>
          {data?.length > 0 && pageCount > 1 ? (
            <PrimaryPagination
              pageCount={pageCount}
              setPageNo={setPageNo}
              pageNo={pageNo}
            />
          ) : null}
        </CardBody>
      </Card>
    </div>
  );
};

export default ProductPurchases;
