import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Button } from "reactstrap";

import TopHeading from "components/Common/TopHeading";
import CancelIcon from "../../assets/images/cancle-icon.svg";
import {
  getAllResponseLogs,
  deleteResponseLogs,
  exportResponseLogs,
} from "services/ResponseLogsServices";
import PrimaryTable from "components/Common/PrimaryTable";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { setPageTitle } from "utils/pageTitle";
import importIcon from "assets/images/upward-arrow.svg";
import PrimaryToast from "components/Common/Toast/ToastMessage";

function ResponseLogs() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [responseLogsData, setResponseLogsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [type, setType] = useState(undefined);
  const history = useNavigate();
  const params = new URLSearchParams(window?.location?.search);

  useEffect(() => {
    setPageTitle("Response Logs - Inspire Uplift");
  }, []);

  useEffect(() => {
    const page = params.get("page") || undefined;
    const typeParam = params.get("type") || undefined;
    setType(typeParam);
    setPageNo(page || 1);
    setLoading(true);
    getData(page, typeParam);
  }, [window.location?.search]);

  const getData = async (page, type) => {
    try {
      const response = await getAllResponseLogs(page, type);
      if (response.data?.data?.length == 0 && page > 1) {
        params?.set("page", page - 1);
        generateUrl();
      } else {
        setPageCount(response?.data.last_page);
        setResponseLogsData(response?.data.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      dataField: "created_at",
      text: "Date",
    },
    {
      dataField: "type",
      text: "Type",
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => (
        <div
          className={`${
            row.status === "Error"
              ? "danger-status"
              : row.status === "Complete"
              ? "success-status"
              : ""
          } status w-max-content `}
        >
          {row.status}
        </div>
      ),
    },
    {
      dataField: "body",
      text: "Body",
      style: {
        maxWidth: "500px",
        whiteSpace: "break-spaces",
        wordBreak: "break-word",
      },
    },
  ];

  const data = responseLogsData?.map(item => {
    return {
      ...item,
    };
  });

  const handleOnSelectAll = (isSelected, rows) => {
    if (isSelected) {
      setSelectedRowKeys(rows.map(row => row.id));
    } else {
      setSelectedRowKeys([]);
    }
  };

  const selectRow = {
    mode: "checkbox",
    onSelectAll: handleOnSelectAll,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedRowKeys([...selectedRowKeys, row.id]);
      } else {
        setSelectedRowKeys(selectedRowKeys.filter(key => key !== row.id));
      }
    },
    selected: selectedRowKeys,
  };

  const handleDelete = () => {
    setLoading(true);
    deleteResponseLogs(selectedRowKeys)
      .then(res => {
        setSelectedRowKeys([]);
        setShowDeleteModal(false);
        getData(pageNo, type);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  const generateUrl = () => {
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    return history(newUrl, { replace: true });
  };

  const handleFilter = () => {
    if (params?.get("page")) {
      params?.delete("page");
    }
    if (type) {
      params.set("type", type);
    } else {
      params.delete("type");
    }
    generateUrl();
  };

  const handleExportLogs = async () => {
    try {
      setLoading(true);
      const resultString = await exportResponseLogs(); // Assuming this returns the CSV string

      // Split the response into rows (assuming it's CSV)
      const rows = resultString.trim().split("\n").slice(1); // Skip header row

      if (rows.length === 0) return; // If no data rows

      const csvRows = [];
      const headers = ["#", "message"];
      csvRows.push(headers.join(",")); // Add headers to CSV

      rows.forEach(row => {
        const matches = row.match(/(\d+),"(.*)"/); // Match the index and message

        if (matches) {
          const num = matches[1]; // This is the index
          const message = matches[2]; // This captures the entire message

          // Ensure the message is properly escaped for CSV format
          const escapedMessage = message.replace(/"/g, '""'); // Escape double quotes

          const values = [
            num,
            `"${escapedMessage}"`, // Enclose message in quotes
          ];

          csvRows.push(values.join(",")); // Add the values to csvRows
        } else {
          console.error("Failed to parse row:", row);
        }
      });

      // Create a Blob from the CSV string
      const csvString = csvRows.join("\n"); // Join with newline for separate rows
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = URL.createObjectURL(blob);

      // Create a temporary link and trigger download
      const a = document.createElement("a");
      a.href = url;
      a.download = "response_log.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (err) {
      console.log(err);
      PrimaryToast({
        type: "error",
        message: err?.message || "An error occurred while exporting logs",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-content">
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure to delete this log?"}
          onConfirm={handleDelete}
          onCancel={() => {
            setSelectedRowKeys([]);
            setShowDeleteModal(!showDeleteModal);
          }}
        />
      )}
      <TopHeading heading={"Response Logs"} />
      {loading && <ProgressPopup label={"Loading..."} />}
      <Card className="mt-4">
        <div className="d-flex justify-content-between mt-3">
          <div className="d-flex">
            <div className="d-flex ms-2">
              <select
                className="primary-select-option"
                value={type || ""}
                onChange={e => {
                  setType(e.target.value);
                }}
                onKeyPress={handleFilter}
              >
                <option value={""}>Search by Log Type</option>
                <option value={"Category_Products"}>Category Products</option>
                <option value={"Order_Place"}>Order Place</option>
                <option value={"track17_error"}>Track17 Error</option>
                <option value={"Mongodb_Seller_Product_Error"}>
                  Mongodb Seller Product Error
                </option>
                <option value={"Mongodb_Seller_Product"}>
                  Mongodb Seller Product
                </option>
                <option value={"MONGODB_PRODUCT_SYNC_ERROR"}>
                  Mongodb Product Sync Error
                </option>
                <option value={"Tracking_events"}>Tracking Events</option>
                <option value={"printful_sync_error"}>
                  Printful Sync Error
                </option>
                <option value={"reviewit_order_refunded_req"}>
                  Reviewit Order Refunded Req
                </option>
                <option value={"reviewit_dispute_decision_req"}>
                  Reviewit Dispute Decision Req
                </option>
                <option value={"multi_edit_error"}>Multi Edit Error</option>
                <option value={"SellerSignUpReviewIt"}>
                  Seller Sign Up Reviewit
                </option>
                <option value={"Tax_invoice_exception"}>
                  Tax Invoice Exception
                </option>
              </select>
            </div>
            <Button
              color="primary"
              className={`btn-rounded  ms-1`}
              onClick={handleFilter}
            >
              Apply Filter
            </Button>
          </div>
          <Button
            type="button"
            color="primary"
            onClick={handleExportLogs}
            disabled={loading}
          >
            <img src={importIcon} alt="import icon" className="pe-2" />
            {loading ? "Exporting..." : "Export csv"}
          </Button>
        </div>

        <CardBody>
          {selectedRowKeys?.length > 0 && (
            <div
              className="d-flex justify-content-end w-100 mb-4"
              onClick={() => {
                setShowDeleteModal(true);
              }}
            >
              <Button
                color="danger"
                className="d-flex align-items-center rounded-full "
              >
                <img src={CancelIcon} alt="cancel icon" className="me-2" />
                Delete
              </Button>
            </div>
          )}
          <PrimaryTable
            columns={columns}
            data={data}
            classes={"table align-middle table-nowrap"}
            headerWrapperClasses={"thead-light"}
            responsive
            bordered={false}
            keyField="id"
            selectRow={selectRow}
          />
          {responseLogsData?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              pageCount={pageCount}
              pageNo={pageNo}
              setPageNo={setPageNo}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default ResponseLogs;
