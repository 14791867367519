import React, { useEffect, useState, useMemo } from "react";
import { Button, Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";

import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { getAllSellersKYCRecords } from "services/SellersKycServices";
import PrimaryTable from "components/Common/PrimaryTable";
import TopHeading from "components/Common/TopHeading";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import GlobalService from "services/GlobalService";
import PrimarySearch from "components/Common/PrimarySearch";
import ResetButton from "components/Common/ResetButton";

export const SellersKYC = () => {
  const [sellersKYCData, setSellersKYCData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [status, setStatus] = useState();
  const params = new URLSearchParams(window.location.search);
  const globalService = GlobalService();
  const navigate = useNavigate();
  useEffect(() => {
    const pageParam = params.get("page") || 1;
    const searchParam = params.get("search");
    const statusParam = params.get("status") || "";
    setStatus(statusParam);
    setPage(pageParam);
    fetchData(pageParam, searchParam, statusParam);
  }, [window?.location?.search]);

  const fetchCountries = async () => {
    const response = await globalService.getCountries();
    return response.data.data;
  };

  const fetchCountriesData = useMemo(() => {
    const data = fetchCountries();
    return data;
  }, []);

  const fetchData = async (page, search, status) => {
    setLoading(true);
    try {
      const response = await getAllSellersKYCRecords(page, search, status);
      const fetchCountries = await fetchCountriesData;
      const sellersData = response.data.data.map(data => {
        return {
          ...data,
          issue_country: fetchCountries?.find(
            country => country.alpha2Code == data?.proof_of_identity?.country
          )?.name,
        };
      });
      setSellersKYCData(sellersData);
      setPageCount(response?.data?.last_page);
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => (
        <div className="font-semi-bold">{(page - 1) * 10 + row.index}</div>
      ),
    },
    {
      dataField: "seller_name",
      text: "Seller Name",
      style: {
        maxWidth: "200px",
        whiteSpace: "break-spaces",
        wordBreak: "break-word",
        overFlow: "hidden",
      },
    },
    {
      dataField: "store_name",
      text: "Store Name",
      style: {
        maxWidth: "500px",
        whiteSpace: "break-spaces",
        wordBreak: "break-word",
      },
    },
    {
      dataField: "issue_country",
      text: "Issue Country",
    },
    {
      dataField: "status",
      text: "KYC Status",
      formatter: (cell, row) => {
        let status;
        switch (row.status) {
          case 0:
            status = "Pending";
            break;
          case 1:
            status = "Approved";
            break;
          case 2:
            status = "Rejected";
            break;
            case 3:
                status = "Submitted";
                break;
          default:
            status = "Pending";
        }
        return (
          <div
            className={`status w-max-content ${
              (status == "Pending" || status == "Submitted")
                ? "warning-status"
                : status == "Approved"
                ? "success-status"
                : status == "Rejected"
                ? "danger-status"
                : ""
            }`}
          >
            {status}
          </div>
        );
      },
    },
  ];

  const data = sellersKYCData?.map((item, index) => {
    return {
      index: index + 1,
      seller_name: item?.seller?.name,
      store_name: item?.seller?.shop_name,
      status: item?.seller?.kyc_status,
      ...item,
    };
  });

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      if (params?.get("page")) {
        params?.delete("page");
      }
      if (searchValue) {
        params?.set("search", searchValue);
      } else {
        params?.delete("search");
      }
      if (status) {
        params?.set("status", status);
      } else {
        params?.delete("status");
      }
      navigate(`/seller/kyc-management?${params?.toString()}`);
    }
  };

  const handleFilter = () => {
    if (params?.get("page")) {
        params?.delete("page");
      }
      if (searchValue) {
        params?.set("search", searchValue);
      } else {
        params?.delete("search");
      }
      if (status) {
        params?.set("status", status);
      } else {
        params?.set("status");
      }
      navigate(`/seller/kyc-management?${params?.toString()}`);
  }

  const paramsLength = params?.toString()?.length;

  return (
    <div className="page-content">
      {loading && <ProgressPopup label="loading..." />}
      <div className="mb-3">
        <TopHeading heading={"Sellers Kyc Records"} />
      </div>
      <Card>
        <CardBody>
          <div className="mb-4 d-flex align-items-center">
            <PrimarySearch
              placeholder={"Search by seller name"}
              value={searchValue || ""}
              handleKeyPress={handleKeyPress}
              handleChange={e => setSearchValue(e.target.value)}
            />
            <select
              className="primary-select-option ms-1"
              name="status"
              value={status || ""}
              onKeyPress={handleKeyPress}
              onChange={e => {
                setStatus(e.target.value);
              }}
            >
              <option value={""}>Select Status</option>
              <option value="Approved"> Approved </option>
              <option value="Rejected"> Rejected </option>
              <option value="Submitted"> Submitted </option>
            </select>
            <Button
              color="primary"
              className={`btn-rounded ms-3`}
              onClick={handleFilter}
            >
              Apply Filter
            </Button>
            {paramsLength > 0 && <ResetButton />}
          </div>
          <PrimaryTable
            columns={columns}
            data={data}
            responsive
            bordered={false}
            keyField="id"
            isPermission={true}
            rowUrl={"/seller/kyc-management"}
          />
          {sellersKYCData?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              pageCount={pageCount}
              pageNo={page}
              CodeWhisperer
              setPageNo={setPage}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};
